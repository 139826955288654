import { useEffect, useMemo, useState } from 'react';

// Libraries
import momentTimezone from 'moment-timezone';
import { useParams } from 'react-router-dom';

// Icons
import LocationOnOutLinedIcon from '@material-ui/icons/LocationOnOutlined';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { queryGolfDetail, queryGolfList, queryTeeTimeList } from 'reduxs/global-actions';

// UTILS
import { Typography } from '@material-ui/core';
import Banner from 'components/Banner';
import { BannerTypes } from 'components/Banner/enum';
import ReducerHelmet from 'components/ReducerHelmet';
import RegularProductDetail from 'components/RegularProductDetail';
import { ProductTypeCode, ReducerStatus } from 'constants/Enums';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import useConstructor from 'hooks/useContrucstor';
import useLangHistory from 'hooks/useLangHistory';
import { useTranslation } from 'react-i18next';
import store from 'reduxs/store';
import { getAddress } from 'utils/common';
import BookingCard from './BookingCard';
import './styles.scss';

function GolfCourseDetailPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLangHistory();
  const { productPath } = useParams();
  const golfCourseList = useSelector(state => state.golfCourseList);
  const golfCourseDetail = useSelector(state => state.golfCourseDetail);
  const [bookingData, setBookingData] = useState({
    productTypeCode: ProductTypeCode.GOLF_TEE_TIME,
    product: null,
    startDate: null,
    endDate: null,
    numOfGuest: 1,
    player: 1,
    nonPlayer: 0,
    quantity: 1,
    unitPrice: null,
    currencyCode: null,
    hotDealOptions: null,
    avatarImg: null,
  });
  const [golfId, setGolfId] = useState(null);

  useConstructor(() => {
    if (golfCourseList.status !== ReducerStatus.SUCCESS || golfCourseList.data == null) {
      dispatch(queryGolfList());
    }
  });

  useEffect(() => {
    dispatch(
      queryGolfDetail(
        {
          customPath: productPath,
        },
        {
          handleSuccess(response) {
            setGolfId(response.data.id);
            setBookingData(prev => ({
              ...prev,
              startDate: momentTimezone.tz(response.data.timezone).format('yyyy-MM-DD'),
              endDate: momentTimezone.tz(response.data.timezone).format('yyyy-MM-DD'),
              avatarImg: response.data.avatarImageUrl,
              currencyCode: response.data.currencyCode,
            }));
            store.dispatch(
              queryTeeTimeList({
                golfCourseId: response.data.id,
                teeDate: momentTimezone.tz(response.data.timezone).format('yyyy-MM-DD'),
                timezone: response.data.timezone,
              })
            );
          },
        }
      )
    );
  }, [window.location.pathname]);

  const onDateChange = date => {
    const formatDate = momentTimezone(date).tz(golfCourseDetail.data.timezone).format('yyyy-MM-DD');
    setBookingData(prev => ({
      ...prev,
      startDate: formatDate,
      endDate: formatDate,
    }));
    store.dispatch(
      queryTeeTimeList({
        golfCourseId: golfCourseDetail.data.id,
        teeDate: formatDate,
        timezone: golfCourseDetail.data.timezone,
      })
    );
  };

  const onPlayerChange = player => {
    setBookingData(prev => ({ ...prev, player, numOfGuest: player }));
  };

  const handleBooking = () => {
    push('complete-booking', bookingData);
  };

  const onChooseTeeTimeClick = teeTime => {
    const hotDealOptions = teeTime?.hotDeal?.filter(el => el.optional);
    setBookingData(prev => ({
      ...prev,
      product: { ...teeTime, productName: golfCourseDetail.data.name },
      unitPrice: teeTime.price,
      hotDealOptions: hotDealOptions?.length > 0 ? hotDealOptions : null,
    }));
  };

  const getBannerImages = useMemo(() => {
    let images = [`${window.location.origin}/img/Banner/golfcourse.jpg`];

    if (golfCourseDetail.data?.detailImageUrlList) {
      if (typeof golfCourseDetail.data?.detailImageUrlList === 'string') {
        try {
          const imageList = JSON.parse(golfCourseDetail.data?.detailImageUrlList);
          images = [...imageList];
        } catch (error) {
          if (process.env.NODE_ENV === 'development') console.log('error', error);
        }
      } else if (Array.isArray(golfCourseDetail.data?.detailImageUrlList)) {
        images = golfCourseDetail.data.detailImageUrlList;
      }
    }

    return images;
  }, [golfCourseDetail.data?.detailImageUrlList]);

  const suggestedProducts = useMemo(() => {
    if (golfCourseList.data == null || golfCourseDetail.data == null) return null;

    return golfCourseList.data
      .filter(golf => golf.id !== golfCourseDetail.data.id)
      .map(golf => ({
        avatarImageUrl: golf.avatarImageUrl,
        title: golf.name,
        subtitle: (
          <Typography variant='caption' className='suggested-subtitle'>
            {golf.address}
          </Typography>
        ),
        price: golf.price,
        href: `product-detail/golf-course/${golf.customPath[window.lang]}`,
      }));
  }, [golfCourseList.data, golfCourseDetail.data]);

  return (
    <>
      {golfId && (
        <ReducerHelmet
          option={META_TAG_OPTION.POST}
          page={META_TAG_PAGE.PRODUCT_DETAIL}
          productType={META_TAG_PRODUCT_TYPE.GOLF_COURSE}
          id={golfId}
        />
      )}

      <Banner bannerType={BannerTypes.IMAGE_CAROUSEL} media={getBannerImages} />

      {golfCourseDetail.data != null && golfCourseDetail.status === ReducerStatus.SUCCESS ? (
        <RegularProductDetail
          title={golfCourseDetail.data.name}
          subTitle={() => (
            <div className='sub-title-info'>
              <div className='row-address'>
                <LocationOnOutLinedIcon className='icon' />
                <Typography variant='subtitle2' className='address'>
                  {getAddress(golfCourseDetail.data)}
                </Typography>
              </div>

              <div className='row-info'>
                <Typography variant='subtitle2' className='left'>
                  {t('Acreage')}
                </Typography>
                <Typography variant='subtitle2' className='right'>
                  157 ha
                </Typography>
              </div>

              <div className='row-info'>
                <Typography variant='subtitle2' className='left'>
                  {t('Hole')}
                </Typography>
                <Typography variant='subtitle2' className='right'>
                  18
                </Typography>
              </div>

              <div className='row-info'>
                <Typography variant='subtitle2' className='left'>
                  {t('Players')}
                </Typography>
                <Typography variant='subtitle2' className='right'>
                  1 - 4 players
                </Typography>
              </div>
            </div>
          )}
          basicDetails={golfCourseDetail.data.description}
          suggestedProducts={suggestedProducts}
        >
          <BookingCard
            bookingData={bookingData}
            onDateChange={onDateChange}
            onPlayerChange={onPlayerChange}
            onChooseTeeTimeClick={onChooseTeeTimeClick}
            onBooking={handleBooking}
          />
        </RegularProductDetail>
      ) : null}
    </>
  );
}

export default GolfCourseDetailPage;
