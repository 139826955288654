import { InputAdornment, MenuItem, MenuList, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

// core component
import RegularButton from 'components/CustomButtons';
import CustomInput from 'components/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// icons
import EmailIcon from '@material-ui/icons/Email';

import classNames from 'classnames';
import MainFrame from 'components/MainFrame';
import SmoothLink from 'components/Routes/SmoothLink';
import { companyServices, socialList } from './FooterList';
import './styles.scss';

const getFooterId = (...args) => args.join('-').toLowerCase().replace(/ /g, '-');

export default function Footer() {
  const { t } = useTranslation();

  return (
    <main id='footer'>
      <footer className='Footer'>
        <MainFrame>
          <div className='Footer__SocialNetwork'>
            <GridContainer className='Footer__SocialNetwork__SubscribeTitleGroup'>
              <GridItem
                sm={4}
                className='Footer__SocialNetwork__SubscribeTitleGroup__TextContainer'
              >
                <Typography
                  variant='h4'
                  className='Footer__SocialNetwork__SubscribeTitleGroup__TextContainer__Title'
                >
                  {t('Newsletter')}
                </Typography>
              </GridItem>
              <GridItem
                sm={8}
                className='Footer__SocialNetwork__SubscribeTitleGroup__TextContainer'
              >
                <Typography
                  variant='body1'
                  className='Footer__SocialNetwork__SubscribeTitleGroup__TextContainer__Example'
                >
                  {t('Newsletter Example')}
                </Typography>
              </GridItem>
            </GridContainer>

            <div className='Footer__SocialNetwork__SubscribeForm'>
              <CustomInput
                formControlProps={{
                  className: 'Footer__SocialNetwork__SubscribeForm__FormControl',
                }}
                inputProps={{
                  placeholder: 'E-mail address',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <RegularButton color='success' size='sm'>
                Subscribe
              </RegularButton>
            </div>

            <div className='Footer__SocialNetwork__VisitUsOnContainer'>
              <Typography variant='h6' className='Footer__SocialNetwork__VisitUsOnContainer__Title'>
                {t('Visit Us On')}:
              </Typography>

              <div className='Footer__SocialNetwork__VisitUsOnContainer__VisitUsOn'>
                {socialList.map(item =>
                  item.type === 'link' ? (
                    <a
                      id={getFooterId('footer', 'social', item.name)}
                      key={item.imgUrl}
                      className='Footer__SocialNetwork__VisitUsOnContainer__VisitUsOn__Icon'
                      href={item.href}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={`${window.location.origin}/${item.imgUrl}`} alt='visit-us-on' />
                    </a>
                  ) : (
                    <Tooltip
                      key={item.imgUrl}
                      title={
                        <img
                          width='100%'
                          height='100%'
                          src={`${window.location.origin}/img/SocialNetwork/qr-code.jpg`}
                          alt=''
                        />
                      }
                      className='Footer__SocialNetwork__VisitUsOnContainer__VisitUsOn__Tooltip'
                    >
                      <img src={`${window.location.origin}/${item.imgUrl}`} alt='' />
                    </Tooltip>
                  )
                )}
              </div>
            </div>
          </div>

          {/* MOBILE STORE */}
          <GridContainer className='Footer__CompanyInfo'>
            <GridItem sm={4} className='Footer__CompanyInfo__Left'>
              <div className='Footer__CompanyInfo__Left__Container'>
                <div className='Footer__CompanyInfo__Left__Container__Name'>
                  <div className='Footer__CompanyInfo__Left__Container__Name__Logo black'>
                    <a href='https://golfadventuretours.com/' target='_blank' rel='noreferrer'>
                      <img
                        src={`${window.location.origin}/img/Global/golfadventure.png`}
                        alt='footer-company-logo'
                      />
                    </a>
                  </div>
                </div>
                <div className='Footer__CompanyInfo__Left__Container__Name'>
                  <div className='Footer__CompanyInfo__Left__Container__Name__Logo white'>
                    <a href='https://dailytoursinvietnam.com/' target='_blank' rel='noreferrer'>
                      <img
                        src={`${window.location.origin}/img/Global/dailytour.png`}
                        alt='footer-company-logo'
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className='Footer__CompanyInfo__Left__Container'>
                <div className='Footer__CompanyInfo__Left__Container__Name'>
                  <div className='Footer__CompanyInfo__Left__Container__Name__Logo'>
                    <img
                      src={`${window.location.origin}/img/Global/appstore.png`}
                      alt='footer-company-logo'
                    />
                  </div>
                </div>
                <div className='Footer__CompanyInfo__Left__Container__Name'>
                  <div className='Footer__CompanyInfo__Left__Container__Name__Logo'>
                    <img
                      src={`${window.location.origin}/img/Global/ggPlay.png`}
                      alt='footer-company-logo'
                    />
                  </div>
                </div>
              </div>
            </GridItem>

            <GridItem sm={8} className='Footer__CompanyInfo__Right'>
              {companyServices.map((companyService, index) => (
                <GridItem sm={6} key={index} className='Footer__CompanyInfo__Right__GroupContainer'>
                  {companyService.map(item => (
                    <GridItem
                      key={item.groupName}
                      sm={12 - companyService.length}
                      className='Footer__CompanyInfo__Right__GroupContainer__Group'
                    >
                      <MenuList
                        className={classNames(
                          'Footer__CompanyInfo__Right__GroupContainer__Group__MenuList',
                          { centered: item.groupName === 'About Us' }
                        )}
                      >
                        <Typography
                          variant='h6'
                          className='Footer__CompanyInfo__Right__GroupContainer__Group__MenuList__GroupTitle'
                        >
                          {t(item.groupName)}
                        </Typography>
                        {item.items.map(subItem => {
                          const id = getFooterId('btn', 'footer', item.groupName, subItem.label);
                          return (
                            <MenuItem
                              key={subItem.label.replace(/ /g, '-')}
                              className={classNames(
                                'Footer__CompanyInfo__Right__GroupContainer__Group__MenuList__MenuItem',
                                { centered: item.groupName === 'About Us' }
                              )}
                            >
                              <SmoothLink
                                id={id}
                                href={subItem.href}
                                to={subItem.hash}
                                target={subItem.target}
                              >
                                <Typography
                                  variant='caption'
                                  className={classNames(
                                    'Footer__CompanyInfo__Right__GroupContainer__Group__MenuList__MenuItem__Text',
                                    { centered: item.groupName === 'About Us' }
                                  )}
                                >
                                  {t(subItem.label)}
                                </Typography>
                              </SmoothLink>
                            </MenuItem>
                          );
                        })}

                        {/* PHONE */}
                        {item.groupName === 'About Us' && (
                          <MenuItem
                            id='btn-footer-about-us-phone'
                            className={classNames(
                              'Footer__CompanyInfo__Right__GroupContainer__Group__MenuList__MenuItem',
                              { centered: true }
                            )}
                            button={false}
                          >
                            <Typography
                              variant='caption'
                              className='Footer__CompanyInfo__Right__GroupContainer__Group__MenuList__MenuItem__Label'
                            >
                              {t('Hotline')}:
                            </Typography>
                            <a id='btn-footer-about-us-phone-84903640601' href='tel:+84903640601'>
                              <Typography variant='caption'>0903 640 601</Typography>
                            </a>
                            <Typography
                              variant='caption'
                              className='Footer__CompanyInfo__Right__GroupContainer__Group__MenuList__MenuItem__Spacing'
                            >
                              |
                            </Typography>
                            <a id='btn-footer-about-us-phone-0287306684' href='tel:+842873066684'>
                              <Typography variant='caption'>028 730 6684</Typography>
                            </a>
                          </MenuItem>
                        )}
                      </MenuList>
                    </GridItem>
                  ))}
                </GridItem>
              ))}
            </GridItem>
          </GridContainer>
        </MainFrame>
      </footer>
    </main>
  );
}
