import { useEffect, useMemo, useState } from 'react';

// Libraries
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { queryGolfTourDetail, queryGolfTourList } from 'reduxs/global-actions';

// UTILS
import Banner from 'components/Banner';
import { BannerTypes } from 'components/Banner/enum';

import ReducerHelmet from 'components/ReducerHelmet';
import RegularProductDetail from 'components/RegularProductDetail';
import { ProductTypeCode, ReducerStatus } from 'constants/Enums';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import useCategories from 'hooks/useCategories';
import useConstructor from 'hooks/useContrucstor';
import useLangHistory from 'hooks/useLangHistory';
import moment from 'moment/moment';
import { getLangString } from 'utils/common';
import BookingCard from './BookingCard';
import './styles.scss';

function GolfTourDetailPage() {
  const dispatch = useDispatch();
  const { getParentPath } = useCategories();
  const { push } = useLangHistory();
  const { productPath } = useParams();
  const golfTourList = useSelector(state => state.golfTourList);
  const golfTourDetail = useSelector(state => state.golfTourDetail);
  const [bookingData, setBookingData] = useState({
    productTypeCode: ProductTypeCode.GOLF_TOUR,
    product: null,
    startDate: null,
    endDate: null,
    numOfGuest: 1,
    player: 1,
    nonPlayer: 0,
    quantity: 1,
    unitPrice: null,
    currencyCode: 'VND',
    hotDealOptions: null,
    avatarImg: null,
  });
  const [golfTourId, setGolfTourId] = useState(null);

  useConstructor(() => {
    if (golfTourList.status === ReducerStatus.REQUEST) {
      dispatch(queryGolfTourList());
    }
  });

  useEffect(() => {
    dispatch(
      queryGolfTourDetail(
        { customPath: productPath },
        {
          handleSuccess: response => {
            setGolfTourId(response.data.id);
            const tourResponse = response.data;
            if (tourResponse == null) return;
            const firstDeparture = tourResponse.golfTourDeparture[0];
            setBookingData(prev => ({
              ...prev,
              product: { ...firstDeparture, productName: tourResponse.name },
              unitPrice: firstDeparture?.price ?? 0,
              avatarImg: tourResponse.avatarImageUrl,
              startDate: firstDeparture?.departureDate,
              endDate: moment(firstDeparture?.departureDate)
                .add(tourResponse.numOfDay, 'days')
                .format('YYYY-MM-DD'),
            }));
          },
        }
      )
    );
  }, [window.location.pathname]);

  const onDepartureDateChange = e => {
    setBookingData(prev => ({
      ...prev,
      product: e.target.value,
      unitPrice: e.target.value?.price ?? 0,
      startDate: e.target.value?.departureDate,
      endDate: moment(e.target.value?.departureDate)
        .add(golfTourDetail.data?.numOfDay, 'days')
        .format('YYYY-MM-DD'),
    }));
  };

  const handleMinusQuantity = () => {
    if (bookingData.quantity > 1)
      setBookingData(prev => {
        const newQty = prev.quantity - 1;
        return {
          ...prev,
          quantity: newQty,
          numOfGuest: newQty,
          player: newQty,
          nonPlayer: newQty,
          unitPrice: newQty * prev.product.price,
        };
      });
  };

  const handlePlusQuantity = () => {
    setBookingData(prev => {
      const newQty = prev.quantity + 1;
      return {
        ...prev,
        quantity: newQty,
        numOfGuest: newQty,
        player: newQty,
        nonPlayer: newQty,
        unitPrice: newQty * prev.product.price,
      };
    });
  };

  const onQuantityChange = e => {
    if (e.target.value >= 1)
      setBookingData(prev => {
        const newQty = e.target.value;
        return {
          ...prev,
          quantity: newQty,
          numOfGuest: newQty,
          player: newQty,
          nonPlayer: newQty,
          unitPrice: newQty * (prev.product.salePrice ?? prev.product.regularPrice),
        };
      });
  };

  const handleBooking = () => {
    push('complete-booking', bookingData);
  };

  const getBannerImages = useMemo(() => {
    let images = [`${window.location.origin}/img/Banner/golfcourse.jpg`];
    const { detailImageUrlList } = golfTourDetail.data ?? {};

    if (detailImageUrlList) {
      if (typeof detailImageUrlList === 'string') {
        try {
          const imageList = JSON.parse(detailImageUrlList);
          images = [...imageList];
        } catch (error) {
          if (process.env.NODE_ENV === 'development') console.log('error', error);
        }
      } else if (Array.isArray(detailImageUrlList)) {
        images = detailImageUrlList;
      }
    }

    return images;
  }, [golfTourDetail.data?.detailImageUrlList]);

  const suggestedProducts = useMemo(() => {
    if (golfTourList.data == null || golfTourDetail.data == null) return null;

    return golfTourList.data
      .filter(tour => tour.id !== golfTourDetail.data.id)
      .map(tour => ({
        avatarImageUrl: tour.avatarImageUrl,
        title: tour.name,
        subtitle: (
          <>
            <Typography variant='caption' className='suggested-subtitle'>
              {`${tour.numOfDay} days ${tour.numOfNight} nights`}
            </Typography>
            <Typography variant='caption' className='suggested-subtitle'>
              {`Available: ${tour.golfTourDeparture[0].availableSeats}`}
            </Typography>
            <Typography variant='caption' className='suggested-subtitle'>
              {`Transport: ${getLangString(tour.transport)}`}
            </Typography>
          </>
        ),
        regularPrice: tour.golfTourDeparture[0].price,
        href: `product-detail/golf-tour/${getParentPath(tour.categoryId, tour.customPath)}`,
      }));
  }, [golfTourList.data, golfTourDetail.data, getParentPath]);

  return (
    <>
      {golfTourId && (
        <ReducerHelmet
          option={META_TAG_OPTION.POST}
          page={META_TAG_PAGE.PRODUCT_DETAIL}
          productType={META_TAG_PRODUCT_TYPE.GOLF_TOUR}
          id={golfTourId}
        />
      )}
      <Banner bannerType={BannerTypes.IMAGE_CAROUSEL} media={getBannerImages} />

      {golfTourDetail.data != null && golfTourDetail.status === ReducerStatus.SUCCESS ? (
        <RegularProductDetail
          title={golfTourDetail.data.name}
          basicDetails={golfTourDetail.data.note}
          suggestedProducts={suggestedProducts}
        >
          <BookingCard
            bookingData={bookingData}
            onDepartureDateChange={onDepartureDateChange}
            handleMinusQuantity={handleMinusQuantity}
            handlePlusQuantity={handlePlusQuantity}
            onQuantityChange={onQuantityChange}
            onBooking={handleBooking}
            departureOptions={golfTourDetail.data.golfTourDeparture}
          />
        </RegularProductDetail>
      ) : null}
    </>
  );
}

export default GolfTourDetailPage;
