import { ReducerStatus } from 'constants/Enums';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { queryMetaDetail } from 'reduxs/global-actions';

const ReducerHelmet = ({ option, page, productType, id }) => {
  const dispatch = useDispatch();
  const metaTagDetail = useSelector(state => state.metaTagDetail);
  const previousDetail = useRef({
    option: null,
    page: null,
    productType: null,
    id: null,
  });

  useEffect(() => {
    console.log('ReducerHelmet', option, page, productType, id);

    if (
      option != null &&
      page != null &&
      productType != null &&
      id != null &&
      (option !== previousDetail.current.option ||
        page !== previousDetail.current.page ||
        productType !== previousDetail.current.productType ||
        id !== previousDetail.current.id)
    ) {
      dispatch(queryMetaDetail({ option, page, productType, id, lang: window.lang }));
      previousDetail.current = { option, page, productType, id };
    }
  }, [option, page, productType, id]);

  if (metaTagDetail.status !== ReducerStatus.SUCCESS || !metaTagDetail.data.length) return null;
  return (
    <Helmet>
      {metaTagDetail.data.map(meta => {
        if (meta.metaName?.toLowerCase() === 'title') {
          return <title key={meta.id}>{meta.metaContent}</title>;
        }

        if (meta.metaName) {
          return <meta key={meta.id} name={meta.metaName} content={meta.metaContent} />;
        }

        return <meta key={meta.id} property={meta.metaProperty} content={meta.metaContent} />;
      })}
    </Helmet>
  );
};

ReducerHelmet.propTypes = {
  option: PropTypes.oneOf([META_TAG_OPTION.PAGE, META_TAG_OPTION.POST]),
  page: PropTypes.oneOf([
    META_TAG_PAGE.HOME,
    META_TAG_PAGE.PRIVACY_POLICY,
    META_TAG_PAGE.PRODUCTS,
    META_TAG_PAGE.PRODUCT_DETAIL,
    META_TAG_PAGE.LOGIN,
  ]),
  productType: PropTypes.oneOf([
    META_TAG_PRODUCT_TYPE.HOME_PAGE,
    META_TAG_PRODUCT_TYPE.PRODUCT_LIST_PAGE,
    META_TAG_PRODUCT_TYPE.GOLF_COURSE,
    META_TAG_PRODUCT_TYPE.GOLF_TOUR,
    META_TAG_PRODUCT_TYPE.TRAVEL,
    META_TAG_PRODUCT_TYPE.BLOG,
  ]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReducerHelmet;
